var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-card',{staticClass:"policy-device-filters-preview tw-flex tw-flex-col"},[_c('v-card-title',{staticClass:"tw-flex tw-justify-between tw-uppercase"},[_c('span',[_vm._v("Devices Preview")]),_c('ax-button',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-divider',{staticClass:"tw-mb-2"}),_c('v-card-text',{staticClass:"tw-mt-3"},[_c('ax-card-banner',{staticClass:"mb-0",attrs:{"icon":_vm.mdiInformationOutline,"banner-type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('policy.edit.previewImpactedWarning'))+" ")])],1),_c('v-card-text',{staticClass:"table tw-flex-grow"},[_c('ax-table',{attrs:{"footer-props":_vm.footer,"headers":_vm.headers,"items":_vm.devices,"server-items-length":_vm.serverItemsLength,"options":_vm.tableState,"column-select":false,"item-key":"id","no-data-text":"No devices matched the filters provided","disable-sort":"","data-test-id":"device-filters-preview-table"},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.os_family",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('ax-os-icon',{attrs:{"os-family":item.os_family}})],1)]}},{key:"item.custom_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.custom_name === null ? item.name : item.custom_name)+" ")]}},{key:"item.ip_addr",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('ax-ip-address-column',{attrs:{"ips":{
              publicAddresses: [item.ip_addr],
              privateAddresses: item.ip_addrs_private,
            },"no-data-text":"No IP Found"}})],1)]}},{key:"item.tags",fn:function(ref){
            var item = ref.item;
return [_c('v-lazy',[_c('ax-device-tags',{attrs:{"tags":item.tags}})],1)]}},{key:"no-data",fn:function(){return [(_vm.deviceGroups.length === 0)?_c('div',[_vm._v(" Not seeing any devices? Make sure to associate groups to this policy and try again. ")]):_vm._e()]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"tw-justify-end tw-px-6 tw-pb-6"},[_c('ax-button',{attrs:{"mode":"secondary","data-test-id":"device-filters-close-table"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v(" Close ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }